<template>
  <KChip v-if="module" v-bind="settings"
    >{{ settings.name }}

    <template v-if="module_id">
      #{{ module_id }}
      <v-icon right size="12" :color="settings.color">mdi-open-in-new</v-icon>
    </template>
  </KChip>
  <KChip v-else grey>-</KChip>
</template>

<script>
import KChip from "@/components/KChip";
export default {
  components: {
    KChip
  },

  props: {
    module: String,
    module_id: [Number, String]
  },

  computed: {
    settings() {
      let defaultSettings = {
        xSmall: true,
        target: "_blank",
        style: { cursor: "pointer" }
      };
      switch (this.module) {
        case "collect":
          return {
            ...defaultSettings,
            color: "purple",
            name: "Coleta",
            href: "/collect/collections/view/" + this.module_id,
            title: "Abrir Coleta"
          };
        default:
          return {
            ...defaultSettings,
            color: "indigo",
            name: "Abastecimento",
            href: "/collect/supplies/view/" + this.module_id,
            title: "Abrir Abastecimento"
          };
      }
    }
  },
  methods: {
    onOpenClick() {
      window.open(this.settings.url);
    }
  }
};
</script>

<style>
</style>
