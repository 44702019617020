<template>
  <KDialog
    :title="`Upload #${form.id}`"
    :visible="visible"
    :loading="loading"
    @click:close="close"
  >
    <KForm>
      <v-row class="my-0">
        <!-- Descrição -->
        <v-col cols="12">
          <KLabel label="Descrição" v-bind="inputSettings">{{
            form.description
          }}</KLabel>
        </v-col>

        <v-col cols="12">
          <SectionHeader title="Ações" />
        </v-col>

        <v-col cols="12">
          <!--  -->
          <Upload
            ref="upload"
            title="Arquivos"
            :accept="['image/jpeg', 'image/png', 'application/pdf']"
            :url-upload="urlUpload"
            :action-delete="(item) => `/financials/upload/${item.id}`"
            :allow-paste-event="true"
            field-name="file"
            :initial-files="form.files"
            :refresh-button="false"
            :delete-button="true"
            :new-page-button="true"
            :thumb-size="120"
            :auto-upload="true"
            :insert-uploaded-file="false"
            :remove-uploaded-file="true"
            :show-uploaded-files="true"
            @click:image="() => {}"
            @upload:success="onUploadSuccess"
          />
        </v-col>
      </v-row>
    </KForm>
  </KDialog>
</template>

<script>
import KForm from "@/components/KForm";
import KLabel from "@/components/KLabel";
import KDialog from "@/components/KDialog";
import SectionHeader from "@/components/SectionHeader";
import Upload from "@/components/Upload";

export default {
  components: {
    KForm,
    KLabel,
    KDialog,
    Upload,
    SectionHeader
  },

  data() {
    return {
      visible: false,
      loading: false,
      form: {},
      inputSettings: {
        dense: true,
        outlined: true,
        disabled: true
      }
    };
  },

  computed: {
    files() {
      if (this.form.files) {
        return this.form.files.map(file => ({
          name: file.original_name,
          url: file.url,
          id: file.id,
          size: file.size,
          mime: file.mime
        }));
      }

      return [];
    },
    urlUpload() {
      return `/financials/upload/${this.form.id}`;
    }
  },

  methods: {
    /**
     *
     *
     */
    onUploadSuccess(file) {
      // Adiciona ao state local
      this.form.files.push(file.data);
      // Adiciona ao state global
      this.$store.dispatch("financials/addFile", {
        id: file.id,
        file: file.data
      });
    },

    open(item) {
      this.form = item;
      this.visible = true;
    },

    close() {
      this.visible = false;
    }
  }
};
</script>

<style>
</style>
