<template>
  <!-- Cabeçalho -->
  <v-row class="mx-0">
    <!-- Lista de condomínios -->
    <v-col cols="12" class="py-0 px-2"> </v-col>
    <v-col cols="12" class="py-0 px-2">
      <v-card outlined class="rounded-lg">
        <v-data-table class="has-click" item-key="id" no-data-text="Nenhuma movimentação encontrada"
          no-results-text="Nenhuma movimentação encontrada" outlined disable-sort disable-filtering
          :footer-props="{ 'items-per-page-options': [20, 50, 100] }" :items-per-page="20" :loading="loading"
          :headers="headers" :items="items" :options.sync="options" :server-items-length="pagination.total"
          @update:options="onChangeOptions" @click:row="(item) => $emit('click:row', item)">
          <!-- eslint-disable vue/valid-v-slot  -->
          <template v-slot:item.icon="{ item }">
            <v-icon v-if="item && item.multiplier === '-1'" color="red">mdi-arrow-down-thin</v-icon>
            <v-icon v-if="item && item.multiplier === '1'" color="green">mdi-arrow-up-thin</v-icon>
          </template>

          <!-- eslint-disable vue/valid-v-slot  -->
          <template v-slot:item.id="{ item }">
            <p class="my-0">{{ item.id }}</p>
            <p class="my-0 text-no-wrap caption grey--text">
              {{ item.public_id }}
            </p>
          </template>

          <!-- eslint-disable vue/valid-v-slot  -->
          <template v-slot:item.date="{ item }">
            <KChip v-if="item.expires_at && item.status.status === 0">Vencimento {{ item.date | dateBR }}</KChip>

            <KChip v-else-if="item.payed_at && item.status.status === 1" cyan>Pago em {{ item.date | dateBR }}</KChip>

            <KChip v-else>{{ item.date | dateBR }}</KChip>
          </template>

          <!-- eslint-disable vue/valid-v-slot  -->
          <template v-slot:item.description="{ item }">
            <span class="caption">{{ item.description }}</span>
          </template>

          <!-- eslint-disable vue/valid-v-slot  -->
          <template v-slot:item.status="{ item }">
            <StatusChip :status="item.status" />
            <!-- Se tiver pendente e vencido -->
            <KChip v-if="item.is_expired" red>Atrasado</KChip>
          </template>

          <!-- eslint-disable vue/valid-v-slot  -->
          <template v-slot:item.module="{ item }">
            <ModuleChip :module="item.module" :module_id="item.module_id" />
          </template>

          <!-- eslint-disable vue/valid-v-slot  -->
          <template v-slot:item.connection_name="{ item }">
            <span class="caption">{{
              item.connection && item.connection.name
              }}</span>
          </template>

          <!-- eslint-disable vue/valid-v-slot  -->
          <template v-slot:item.customer="{ item }">
            <span class="caption">
              {{ item.customer && item.customer.name }}
            </span>
          </template>

          <!-- eslint-disable vue/valid-v-slot  -->
          <template v-slot:item.method="{ item }">
            <span class="caption">
              {{ item.method && item.method.name }}
            </span>
          </template>

          <!-- eslint-disable vue/valid-v-slot  -->
          <template v-slot:item.category="{ item }">
            <span class="caption">
              {{ item.category && item.category.name }}
            </span>
          </template>

          <!-- eslint-disable vue/valid-v-slot  -->
          <template v-slot:item.actions="{ item }">
            <v-row class="flex-nowrap">
              <!-- Abrir boleot -->
              <v-btn v-if="item.billet_text_line" icon title="Ver boleto" @click.stop="onOpenBilletClick(item)">
                <v-icon>mdi-barcode</v-icon>
              </v-btn>

              <!-- Gerar boleto -->
              <v-btn v-if="
                !item.billet_text_line &&
                item.multiplier == 1 &&
                item.status.status == 0
              " title="Gerar boleto" icon @click.stop="onGenerateBilletClick(item)">
                <v-icon>mdi-barcode-off</v-icon>
              </v-btn>

              <!-- Upload de comprovante -->
              <v-badge color="rgba(100,100,100,0.7)" offset-x="20" offset-y="20" :value="item.files.length"
                :content="item.files.length">
                <v-btn icon @click.stop="onUploadClick(item)" title="Upload">
                  <v-icon>mdi-cloud-upload-outline</v-icon>
                </v-btn>
              </v-badge>

              <!--  -->
            </v-row>
          </template>

          <!-- eslint-disable vue/valid-v-slot  -->
          <template v-slot:item.amount="{ item }">
            <!-- Se for cancelado -->
            <template v-if="item.status.status == '-1'">
              <span class="grey--text text-decoration-line-through">{{
                item.payed_amount | currency
                }}</span>
            </template>
            <template v-else>
              <!-- Se o valor original e o valor pago forem diferentes -->
              <template v-if="item.payed_amount && item.amount != item.payed_amount">
                <p class="grey--text text-decoration-line-through mb-0">
                  {{ item.amount | currency }}
                </p>
                <!-- Se for receita -->
                <template v-if="+item.multiplier == 1">
                  <span class="green--text">{{
                    item.payed_amount | currency
                    }}</span>
                </template>
              </template>

              <!-- Se os valores forem iguais -->
              <template v-else>

                <!-- Se for receita -->
                <template v-if="+item.multiplier == 1">
                  <span class="green--text">{{
                    item.amount | currency
                    }}</span>
                </template>

                <!-- Se for despesa -->
                <template v-else>
                  <span class="red--text">{{
                    item.amount | currency
                    }}</span>
                </template>

              </template>
            </template>
          </template>
        </v-data-table>
      </v-card>
    </v-col>

    <!-- Espaçamento -->
    <div style="height: 64px; width: 100%"></div>
  </v-row>
</template>

<script>
import KChip from "@/components/KChip";
import StatusChip from "../_shared/StatusChip";
import ModuleChip from "../_shared/ModuleChip";

export default {
  components: { KChip, StatusChip, ModuleChip },

  props: {
    items: {
      type: Array,
      default: () => []
    },
    pagination: {
      type: Object,
      default: () => ({ page: 1, total: 0, perPage: 20, lastPage: 1 })
    },
    loading: Boolean
  },

  watch: {
    pagination(value) {
      this.options = {
        ...this.options,
        page: this.pagination.page,
        itemsPerPage: this.pagination.perPage
      };
    }
  },

  data() {
    return {
      headers: [
        { text: "", value: "icon", sortable: false },
        { text: "Código", sortable: false, value: "id" },
        { text: "Status", value: "status", sortable: false },
        { text: "Data", sortable: false, value: "date" },
        { text: "Descrição", sortable: false, value: "description" },
        { text: "Módulo", value: "module", sortable: false },
        { text: "Conexão", value: "connection_name", sortable: false },
        { text: "Cliente", value: "customer", sortable: false },
        { text: "Método de Pagamento", value: "method", sortable: false },
        { text: "Categoria", value: "category", sortable: false },
        { text: "Ações", value: "actions", sortable: false },
        { text: "Valor", value: "amount", align: "end", sortable: false }
      ],
      totalDesserts: 0,
      options: {}
    };
  },

  methods: {
    onChangeOptions({ itemsPerPage, page }) {
      this.$emit("click:change-pagination", { limit: itemsPerPage, page });
    },

    /**
     *
     *
     */
    onOpenBilletClick(item) {
      this.$emit("click:open_billet", item);
    },

    /**
     *
     *
     */
    onGenerateBilletClick(item) {
      this.$emit("click:generate_billet", item);
    },

    /**
     *
     *
     */
    onUploadClick(item) {
      this.$emit("click:upload", item);
    }
  }
};
</script>

<style>
.has-click.v-data-table tr {
  cursor: pointer;
}
</style>
