<template>
  <v-card outlined rounded class="mx-2 overflow-hidden rounded-lg">
    <v-expansion-panels flat v-model="isOpened">
      <v-expansion-panel>
        <v-expansion-panel-header class="py-0">
          <v-toolbar flat color="transparent">
            <v-btn icon @click.stop="onRefreshClick">
              <v-icon> mdi-refresh </v-icon>
            </v-btn>

            <v-badge
              :content="payloadLength"
              :value="payloadLength"
              color="accent"
            >
              Filtros
            </v-badge>
          </v-toolbar>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <!--  -->
          <KForm ref="form">
            <v-row class="ma-0">
              <!-- Filtrar por mês -->
              <v-col class="py-0" cols="6" md="3">
                <KInputMonth
                  clearable
                  dense
                  outlined
                  label="Filtrar por mês"
                  :error-messages="errors.month"
                  v-model="form.month"
                  persistent-hint
                  required
                ></KInputMonth>
              </v-col>

              <!-- Receita/Despesa -->
              <v-col class="py-0" cols="6" md="3">
                <v-select
                  mandatory
                  dense
                  clearable
                  outlined
                  label="Receita/Despesa"
                  persistent-hint
                  required
                  item-text="text"
                  item-value="value"
                  :error-messages="errors.multiplier"
                  :items="multiplierItems"
                  v-model="form.multiplier"
                ></v-select>
              </v-col>

              <!-- Categoria -->
              <v-col class="py-0" cols="6" md="3">
                <v-autocomplete
                  dense
                  clearable
                  outlined
                  label="Categoria"
                  item-value="id"
                  item-text="full"
                  :items="categoryItems"
                  v-model="form.category_id"
                />
              </v-col>

              <!-- Filtro por Pessoa -->
              <v-col class="py-0" cols="6" md="3">
                <PeopleAutocomplete
                  outlined
                  clearable
                  dense
                  label="Filtrar por pessoa"
                  hint="Digite para começar a pesquisar"
                  persistent-hint
                  v-model="form.customer_id"
                  :search-params="{}"
                  v-bind="$attrs"
                />
              </v-col>

              <!-- Pesquisar por Descrição -->
              <v-col class="py-0" cols="6" md="3">
                <v-text-field
                  dense
                  clearable
                  outlined
                  label="Pesquisar por Descrição"
                  :error-messages="errors.description"
                  v-model="form.description"
                  persistent-hint
                  required
                ></v-text-field>
              </v-col>

              <!-- Filtrar por Status -->
              <v-col class="py-0" cols="6" md="3">
                <v-select
                  mandatory
                  dense
                  clearable
                  outlined
                  label="Status"
                  persistent-hint
                  required
                  item-text="name"
                  item-value="id"
                  :error-messages="errors.status_id"
                  :items="statusItems"
                  v-model="form.status_id"
                >
                  <template v-slot:item="{ item }">
                    <v-list-item-content>
                      <v-list-item-title
                        v-html="item.name"
                        class="caption font-weight-medium"
                      ></v-list-item-title>
                      <v-list-item-subtitle
                        v-html="item.description"
                        class="caption"
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-select>
              </v-col>

              <!-- Filtrar por Módulo -->
              <v-col class="py-0" cols="6" md="3">
                <v-select
                  dense
                  clearable
                  outlined
                  label="Filtrar por módulo"
                  persistent-hint
                  required
                  item-text="text"
                  item-value="value"
                  :error-messages="errors.module"
                  :items="moduleItems"
                  v-model="form.module"
                ></v-select>
              </v-col>

              <!--  -->
              <v-col class="py-0" cols="6" md="3">
                <v-text-field
                  dense
                  clearable
                  outlined
                  label="id do módulo"
                  persistent-hint
                  required
                  v-model="form.module_id"
                ></v-text-field>
              </v-col>

              <!--  -->
              <v-col class="py-0" cols="6" md="3">
                <v-switch
                  class="my-0"
                  inset
                  hide-details
                  label="Somente Cobranças"
                  v-model="form.is_charge"
                ></v-switch>
              </v-col>

              <!--  -->
              <v-col class="py-0" cols="6" md="3">
                <v-switch
                  class="my-0"
                  inset
                  hide-details
                  label="Somente Atrasados"
                  v-model="form.is_expired"
                ></v-switch>
              </v-col>

              <!--  -->
              <v-col class="mt-2 py-0 pb-2" cols="12">
                <v-row
                  class="
                    my-0
                    flex
                    justify-center
                    align-center align-items-center
                  "
                >
                  <v-btn
                    depressed
                    rounded
                    color="neutral"
                    class="align-self-center mx-1"
                    @click="onClick"
                  >
                    <v-icon left>mdi-filter</v-icon>

                    Aplicar Filtro
                  </v-btn>
                  <v-btn
                    depressed
                    rounded
                    color="neutral"
                    class="align-self-center mx-1"
                    @click="onReportClick"
                  >
                    <v-icon left>mdi-microsoft-excel</v-icon>

                    Exportar
                  </v-btn>
                </v-row>
              </v-col>
            </v-row>
          </KForm>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-card>
</template>

<script>
import Storage from "@/plugins/Storage";

import KForm from "@/components/KForm";
import KInputMonth from "@/components/KInput/Month";
import KInputSelect from "@/components/KInput/Select";
import PeopleAutocomplete from "@/components/PeopleAutocomplete";

export default {
  components: { KForm, KInputMonth, PeopleAutocomplete, KInputSelect },

  props: {
    value: Object
  },

  data() {
    return {
      errors: {},
      form: {},
      info: {},
      isOpened: false
    };
  },

  watch: {
    value(val) {
      this.form = val;

      this.$set(this.form, "status_id", val.status_id ? +val.status_id : null); // converte o status_id pra int
    },
    isOpened(val) {
      if (val === 0) {
        Storage.set("financial-filter-is-opened", true);
      } else {
        Storage.set("financial-filter-is-opened", false);
      }
    }
  },

  computed: {
    multiplierItems() {
      return this.$store.getters["financials/multipliers"];
    },
    moduleItems() {
      return this.$store.getters["financials/modules"];
    },
    statusItems() {
      return this.$store.getters["financial_statuses/allImportant"];
    },
    categoryItems() {
      return this.$store.getters["financial_categories/all"];
    },

    /**
     * Devolve a busca removida os itens vazios
     */
    payload() {
      try {
        const payload = {};
        Object.keys(this.form)
          .filter(key => this.form[key])
          .forEach(key => {
            payload[key] = this.form[key];
          });

        return payload;
      } catch (error) {
        return {};
      }
    },
    payloadLength() {
      return Object.keys(this.payload).length;
    }
  },

  methods: {
    onClick() {
      this.$router.replace({ query: this.payload });
      this.$emit("submit", this.payload);
    },
    onReportClick() {
      this.$emit("click:report", this.payload);
    },
    onRefreshClick() {
      this.$emit("refresh", this.payload);
    }
  },

  mounted() {},

  created() {
    // Pega a informação salva no storage se é para mostrar o filtro aberto ou fechado
    const isOpened = Storage.get("financial-filter-is-opened");

    // Caso esteja true, marque isOpened com 0, que é o primeiro índice do expansion.
    // Caso não seja true, deixe a variável como undefined, ou seja, não abre o expansion
    if (isOpened) this.isOpened = 0;
    else this.isOpened = undefined;
  }
};
</script>

<style>
</style>
