<template>
  <KPage fluid>
    <!-- Barra de busca -->
    <SearchFilter v-model="filter" ref="search_filter" @refresh="onRefresh" @submit="onSearchClick"
      @click:report="onReportClick" />

    <!--  -->
    <SectionHeader title="Resultados" class="ma-2" @refresh="onRefreshClick"
      :actions="[{ icon: 'mdi-refresh', label: 'Atualizar', eventName: 'refresh' }]" />

    <!--Cards de resumo -->
    <ResumeCardList fluid :items="searchResume" />

    <!-- Resultados -->
    <ItemsList :items="items" :pagination="pagination" :loading="loading" @click:change-pagination="onChangePagination"
      @click:row="onItemClick" @click:open_billet="onOpenBilletClick" @click:generate_billet="onGenerateBilletClick"
      @click:upload="onUploadClick" />

    <!-- Paginação -->
    <!-- Botão FAB -->
    <SpeedDial :visible="!loading" :actions="speedDialActions" @click:add_expense="openToAddExpense"
      @click:add_income="openToAddIncome" @click:add_charge="openToAddCharge"></SpeedDial>

    <!--  -->
    <DialogViewCharge ref="dialog_view_charge" @save="onSave" />
    <DialogAddCharge ref="dialog_add_charge" @save="onSave" />
    <DialogSave ref="dialog_save" @save="onSave" />
    <DialogUpload ref="dialog_upload" />
  </KPage>
</template>

<script>
import moment from "moment";

import KPage from "@/components/KPage";
import SectionHeader from "@/components/SectionHeader";
import PaginationBar from "@/components/Pagination/Bar";
import SpeedDial from "@/components/SpeedDial";

import SearchFilter from "./Filter";
import ResumeCardList from "../_shared/ResumeCardList";
import ItemsList from "./ItemsList";

import DialogViewCharge from "../_shared/DialogViewCharge";
import DialogAddCharge from "../_shared/DialogAddCharge";
import DialogSave from "../_shared/DialogSave";
import DialogUpload from "../_shared/DialogUpload";

export default {
  components: {
    KPage,
    PaginationBar,
    SectionHeader,
    //
    SearchFilter,
    ResumeCardList,
    SpeedDial,
    DialogViewCharge,
    DialogAddCharge,
    DialogSave,
    DialogUpload,

    ItemsList,
  },

  data() {
    return {
      loading: false,
      page: 1,
      limit: 20,
      filter: {},
    };
  },

  watch: {
    filter(nv, ov) {
      // console.log("watch filter: ", { ...nv }, { ...ov });
    },
  },

  computed: {
    items() {
      return this.$store.getters["financials/all"];
    },

    pagination() {
      return this.$store.getters["financials/pagination"];
    },

    /**
     *
     */
    searchResume() {
      return this.$store.getters["financials/searchResume"];
    },

    speedDialActions() {
      return [
        {
          eventName: "click:add_expense",
          label: "Nova Despesa",
          icon: "mdi-arrow-down-thin",
          color: "red",
        },
        {
          eventName: "click:add_income",
          label: "Nova Receita",
          icon: "mdi-arrow-up-thin",
          color: "green",
        },
        {
          eventName: "click:add_charge",
          label: "Nova Cobrança",
          icon: "mdi-barcode",
          color: "black",
        },
      ];
    },
  },

  methods: {
    async onRefresh(params = {}) {
      // console.log("atualizou");
      this.filter = params;
      await this.$nextTick();
      this.onLoad(this.page, this.limit, params);
    },

    async onChangePagination({ page, limit }) {
      // console.log("trocou pagination", page, limit);
      this.page = page;
      this.limit = limit;

      await this.$nextTick();

      this.onLoad(page, limit, this.filter);
    },

    async onRefreshClick(){
      this.onLoad(this.page, this.limit, this.filter)
    },

    onSearchClick(params) {
      // console.log("search params", params);
      this.filter = params;
      this.onLoad(undefined, undefined, params);
    },

    async onReportClick(params) {
      try {
        // this.$message.show("Relatório está sendo gerado");
        this.$loading.show("Aguarde enquanto geramos o relatório");

        // setTimeout(() => {
        //   this.$message.show("pegadinha do malandro");
        // }, 2000);
        await this.$api.download(
          "/financials/reports/search",
          params,
          "relatorio.xlsx"
        );
        this.$loading.hide();
      } catch (error) {
        this.$loading.hide();
        this.$message.serverError(error);
      }
    },

    async onSave() {
      await this.$nextTick();
      this.onLoad(this.page, this.limit, this.filter);
    },

    /**
     * Ao clicar no item da lista
     */
    onItemClick(item) {
      if (item.billet_text_line) {
        this.$refs["dialog_view_charge"].open(item);
      } else {
        this.$refs["dialog_save"].open({ ...item });
      }
    },

    /**
     *
     */
    openToAddExpense() {
      this.$refs["dialog_save"].open({ multiplier: "-1" });
    },

    /**
     *
     */
    openToAddIncome() {
      this.$refs["dialog_save"].open({ multiplier: "1" });
    },

    /**
     * Cria uma nova cobrança
     */
    openToAddCharge() {
      this.$refs["dialog_add_charge"].open();
    },

    /**
     * Carrega as informações
     */
    async onLoad(page = 1, limit = 20, params = null) {
      try {
        await this.$nextTick();
        const payload = { full: true, page, limit, ...params };
        // console.log("no onload", payload);

        if (this.loading) {
          // console.log("já tem um carregando. ", payload);
          return;
        }

        this.loading = true;

        await Promise.all([
          this.$store.dispatch("financials/refresh", payload),
          this.$store.dispatch("financials/updateResume", payload),
        ]);

        // this.$loading.hide();
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.$message.serverError(error);
        // this.$loading.hide();
        console.log(error);
      }
    },

    /**
     * Injeta os parâmetros da url na
     */
    injectQueryParams() {
      // Parâmetro da url
      const query = this.$route.query;
      // Lista branca de parâmetros
      const whiteList = [
        "month",
        "multiplier",
        "status_id",
        "module",
        "module_id",
        "description",
        "is_expired",
        "is_charge",
      ];

      // Dados filtrados
      const filter = {};

      // console.log("query", query);

      // Se não tiver mês selecionado, selecione o mês atual
      // if (!query.month) filter.month = moment().format("YYYY-MM");

      // Filtra os parâmetros da url de acordo com os valores da lista branca
      whiteList
        .filter(white => query[white])
        .forEach(white => {
          filter[white] = query[white];
        });

      // Injeta os parâmetros da url nas informações do filtro
      // this.$refs["search_filter"].init(filter);
      this.filter = filter;
    },

    /**
     * Abre o boleto web
     */
    onOpenBilletClick(item) {
      const url = this.$api.url(
        `/financials/charges/${item.public_id}/billet_web`
      );
      window.open(url);
    },

    /**
     *
     */
    async onGenerateBilletClick(item) {
      try {
        this.$loading.show("Aguarde enquanto o boleto é gerado");
        await this.$store.dispatch("financials/billetGenerate", item.public_id);
        this.$message.success("Boleto gerado com sucesso");
        this.$loading.hide();
      } catch (error) {
        this.$loading.hide();
        this.$message.serverError(error);
      }
    },

    /**
     *
     */
    onUploadClick({ ...item }) {
      // console.log(item);
      this.$refs["dialog_upload"].open({ ...item });
    },
  },

  mounted() {
    this.injectQueryParams();
  },

  created() {
    this.$store.dispatch("financial_statuses/all");
    this.$store.dispatch("financial_categories/all");
    this.$store.dispatch("financial_methods/all");
    this.$store.dispatch("financial_gateways/all");
    this.$store.dispatch("financial_connections/all");
  },
};
</script>

<style></style>
